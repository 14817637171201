import React from "react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import Hopping from "../../img/hopping_pa_vatnet.jpg"

function HoppingPaKjosnesfjorden() {
  return (
    <Layout>
      <div className="mb-12">
        <div>
          <img
            className="h-80 w-full object-cover object-center rounded-lg shadow-lg mt-6"
            src={Hopping}
            alt="Hopping på Kjøsnesfjorden på Sægrov i Jølster"
          />
        </div>
        <h1 className="text-4xl text-yellow-700 text-center mt-12 font-bold ">
          Hopping på vatnet i Kjølsnesfjorden.
        </h1>
        <p className="text-lg text-center mt-12 w-3/4 mx-auto">
          Arrangementet vil verte gjennomført på slutten av sommaren. Meir
          informasjon kjem seinare. Du kan sjå ein video frå tidlegare
          arrangement mens du ventar....
        </p>
        <div className="shadow-2xl mt-12 mx-auto mb-12 xl:w-1/2 w-3/4 mx-auto">
          <ReactPlayer
            width="100%"
            controls="true"
            url="https://www.youtube.com/watch?v=ntdZxkjZMZQ"
            playing={true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default HoppingPaKjosnesfjorden
